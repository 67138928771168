export default {
  logistics: {
    logistics_delivery_way: "运输交付方式",
    pay_sum: "支付金额",
    city: "城市",
    pay: "支付",
    transport_plan: "运输计划",
    transport_plan_name: "运输计划名称",
    transport_box_number: "运输箱数",
    please_input_plan_name_or_no: "请输入计划名称或编号",
    account_balance: "账户余额",
    not_sufficient_funds: "余额不足",
    immediately_top_up: "立即充值",
    account_balance_pay: "账户余额支付",
    please_select_payment_method: "请选择支付方式",
    service_charge: "手续费",
    nonsupport_logistics: "当前不支持物流",
    customs_clearance_information_incomplete: "清关信息未填完整",
    chargeable_weight: "计费重",
    chargeable_volume: "计费体积",
    volume_weight_coefficient: "体积重系数: 除",
    create_transport_plan: "创建运输计划",
    set_transport_info: "设置运输信息",
    confirm_transport_plan: "确认运输计划",
    transportation_plan_number: "运输计划编号",
    transportation_plan_name: "运输计划名称",
    destination_type: "目的地类型",
    fba_warehouse_code: "FBA仓库代码",
    warehouse_code: "仓库代码",
    shipping_address: "运送地址",
    receiving_address: "收货地址",
    originate: "发自",
    plan_name_not_null: "计划名称不能为空",
    transport_route: "运输路线",
    shipments_address: "发货地址",
    shipments_warehouse: "发货仓",
    logistics_center_harvest_address: "物流中心收货地址",
    contacts: "联系人",
    shipments_contacts: "发货人",
    recipients: "收件人",
    return_contact_name: "退货联系人",
    business_packaging_size: "商品包装尺寸",
    before_please_select_inventory_warehouse: "请先选择库存所在仓库",

    size: "尺寸",
    logistics_select_hint: "系统根据您的发货地自动匹配最优物流中心",
    max_capacity: "最大容量",
    weight_packing_material: "包装材料重量",
    cost: "费用",
    weight: "重量",
    se_logistics: "赛易物流",
    pack_material: "包装材料",
    product: "产品",
    currency_inconsistency: "清关信息中申报价格的币种需一致",
    summary_customs_clearance_information: "清关信息汇总",
    save_and_order: "保存&下单",
    count: "数量",
    contact_number: "联系电话",
    send_out_contact_number: "发货人联系电话",
    receiving_contact_number: "收件人联系电话",
    usable_inventory: "可用库存",
    fba_transport_no_placeholder: "12位数字和字母组成,如:FBA12ABC123456",
    product_track_no_placeholder: "8位数字和字母组成,如:2AB3CDEF",
    trade_name: "品名",
    apply_middle_rate: "申报平均价",
    number: "数量",
    subtotal: "小计",
    logistics_channel: "物流渠道",
    logistics_scheme: "物流方案",
    changing_the_data_will_affect_the_truck_quotation_of_logistics_please_re_select_logistics: "改变数据将影响物流的卡车报价,请重新选择物流",
    please_select_logistics_scheme: "请选择物流方案",
    delivery_way: "派送方式",
    logistics_type: "物流类型",
    freight_forecast_no_audit: "运费(预估)",
    create_date: "创建日期",
    selected_service: "已选服务",
    clearance_way: "报关方式",
    clearance_file_not_upload_accomplish: "报关文件未上传完整",
    ordinary_trade_clearance: "一般贸易报关",
    transport_costs: "运输费用",
    freight: "运费",
    support_pdf_format: "支持PDF格式",
    customs_declaration: "报关单",
    remote_site: "偏远地址",
    business_site: "商业地址",
    residence: "住宅地址",
    contract: "合同",
    invoice: "发票",
    packing_documents: "装箱单",
    address_supports_door_to_door_service: "该地址支持上门揽件服务",
    transport_plan_details: "运输计划详情",
    door_took: "上门揽件",
    estimated_time_of_arrival: "预计到达的时间",
    transport_info: "运输信息",
    warehouse_address: "仓库地址",
    created_trans_plan: "创建运输计划",
    consignment_create_order: "创建配送订单",
    select_logistics: "选择物流",
    select_logistics_plan: "选择物流方案",
    local_transport: "本土运输",
    have_paid: "已支付",

    consignment_warehouse_no: "配送仓代码",
    consignment: "配送仓",
    source_of_sales: "销售来源",
    distribution_channels: "销售渠道",
    consignment_order_no: "配送订单号",
    relation_order_no: "关联订单号",
    transport_scheme: "运输方案",
    logistics_track_no: "跟踪号",
    start_country_city: "始发国家/城市",
    destination_country_city: "目的国家/城市",
    city_search: "城市(请搜索)",

    enter_boxNo_PlanName_number: "箱号/自定义箱号/入库计划名称/编号",
    dispose_amount: "处理费用",
    transport_charge: "运输费用",
    transport_count: "运输数量",
    transport_box_count: "运输箱数",
    destination_address: "目的地址",
    relevance_out_no: "关联出库编号",
    relevance_in_no: "关联入库编号",
    subsequent_steps: "后续步骤",
    placeholder_order_recipients: "运单号/关联订单号/收件人",
    look: "查看",
    time: "时间",
    status: "状态",
    add_goods: "添加商品",
    please_select_consignment_warehouse: "请选择配送仓库",
    consignment_warehouse: "配送仓库",
    order_info: "订单信息",
    new: "新建",
    new_source_of_sales: "新建销售来源",
    please_input_source_of_sales: "请输入销售来源",
    consignment_warehouse_no_gooods:
      "在我们的配送仓库中没有您的商品,请先往配送仓库添加商品.",
    cannot_create_order: "无法创建配送订单!",

    facility_extent: "透支额度",
    associated_order_number: "关联订单号",
    shipping_plan: "运输计划",

    support_repository: "支持调拨至本地存储仓",
    support_distribution_warehouse: "支持转入本地配送仓",
    area_code: "区号",
    add_driver_contact_information: "添加司机联系方式",
    contact_information: "联系方式",
    four_in_one_file: "四合一文件",
    four_in_one_file_tooltip: "报关单,合同,发票,装箱单",

    separate_file: "分开的文件",
    not_currently_available_logistics_scheme: "暂无可用的物流方案",
    estimated_freight_tips: "预估运费不包含任何附加费项目",
    place_an_order_and_get_a_transfer: "下单并获取转单",
    please_select_channel: "请选择渠道",
    waybill_number: "运单号",
    packaging_figure: "包装图",
    receiving_site: "接收网点",
    canceled: "已取消",
    select_quote: "选择报价",
    carrier: "承运商",
    price: "价格",
    aging: "时效",
    logistics: "物流",
    truck: "卡车",
    quote: "报价",
    quote_options: "报价选项",
    quote_details: "报价详情",
    quote_options_tips: "卡车价格波动较大，需实时询价",
    quote_type: "报价类型",
    service_level: "服务等级",
    additional_options: "附加选项",
    inquiry_immediately: "立即询价",
    reselect: "重新选择",
    next_month: "次月",
    exceptions_will_not_be_uploaded_whether_to_submit: "有异常的运输计划将不会被上传，是否确认提交?",
    only_the_correct_data_will_be_imported: "只有正确的数据才会被导入",
    billing_weight: "计费重量",
    billing_volume: "计费体积",
    volume_weight: "体积重",
    actual_weight: "实重",
    density_volume: "密度体积",
    actual_volume: "实际体积",
    payment_countdown: "支付倒计时",
    forecast_volume: "预报体积",
    forecast_weight: "预报重量",
    print_warehouse_receipt: "打印入仓单",
    print_bill: "打印账单",
    export_bill: "导出账单",
    total_cost: "总费用",
    user_defined_box_no_maximum_of_p0_characters: "一行一个箱号或者使用逗号分隔，单个箱号有字母数字或下划线组成，且长度不超过{0}位字符",
    purchase_insurance: "购买保险",
  },
};
